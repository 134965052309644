@mixin a($e) { @extend [data-a~="#{$e}"]; }
@mixin d($e) { @extend [data-d~="#{$e}"]; }
@mixin g($e) { @extend [data-g~="#{$e}"]; }





[data-p~="1__1"] {
	background: white !important;
	width: initial !important;
	height: 2px !important;
    top: initial !important;
	right: 0 !important;
	left: 0 !important;

    span {
        background: var(--color-1st) !important;
    }
}

[data-p~="2__1"] {
	&:checked {
		~ [data-p~="2__2"] {
			border-color: var(--color-1st);

			[data-p~="2__3"] {
				color: var(--color-1st);
			}
		}
	}
}



[data-p~="text_1__1"] {
	@include d("ft-fy=2nd-rr");
	@include d("co=7th");
	@include d("ft-se=30px");
	@include d("lr-sg=1px");
	@include d("le-ht=1");

	@include d("@1600=ft-se=24px");
	@include d("@1000=ft-se=18px");
}






